import React from 'react';
import { Link } from 'gatsby';
import Image from "../Image";
import './RelatedProjects.scss';

export const RelatedProjects = (props) => {
  const {
    relatedProjects,
    projectGallerySlug,
  } = props;
  const {
    edges = []
  } = relatedProjects;
  const relatedProjectItems = (edges && edges.length > 0) ? relatedProjects.edges.map(project => project.node) : [];
  if (!relatedProjectItems || relatedProjectItems.length < 1) return null;
  return (
    <div className="related-projects">
      <div className="wrap">
        <span className="heading">Related Projects</span>
        <ul>
          {relatedProjectItems && relatedProjectItems.map((project) => {
            const {
              featuredImage,
              slug,
              title,
            } = project;
            return (
              <li key={slug}>
                <Link to={`/${projectGallerySlug}/${slug}/`}>
                  <div className="featured-image">
                    <Image className="inner-image" image={featuredImage} />
                  </div>
                  <div className="related-title">
                    <span>{title}</span>
                  </div>
                </Link>
              </li>
          )})}
        </ul>
      </div>
    </div>
  );
};
