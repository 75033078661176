import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Previewable from '../components/Previewable';
import { RelatedProjects } from '../components/Projects/RelatedProjects';
import {
  TestimonialsBlock,
  LogosBlock,
  ContactInformationBlock
} from '../acf';
import SEO from '../components/SEO';
import './project.scss';
import { Breadcrumbs } from '../components/Breadcrumbs'

export const ProjectTemplate = (props) => {
  const {
      acf,
      relatedProjects,
      siteMetadata,
      location,
    } = props;
    const {
      projectName,
      projectLocation,
      projectType,
      costPerSquareMetre,
      floorArea,
      duration,
      jobDescription,
      images
    } = acf;
    const {
      projectGallerySlug
    } = siteMetadata;
    let sliderSettings = {};
    if (images && images.length > 1) {
      sliderSettings = {
        dots: true,
        arrows: true,
        infinite: true,
        autoplay: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '15%',
        responsive: [
          {
            breakpoint: 960,
            settings: {
              centerPadding: '10%',
            }
          },
          {
            breakpoint: 750,
            settings: {
              centerMode: false,
            }
          }
        ]
      };
    } else {
      sliderSettings = {
        dots: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false
      };
    }

    return (
      <main className="content" role="main">
        <article className="project-gallery-post">
          {(images && images.length > 0) && (
            <ul className="project-images">
              <Slider {...sliderSettings}>
                {images.map((imageItem) => {
                  const {
                    image,
                  } = imageItem;
                  return (
                    <li className="image" key={image.id}>
                      <Image className="inner-image" image={image} />
                    </li>
                  );
                })}
              </Slider>
            </ul>
          )}
          <section className="breadcrumbs-container">
            <div className="wrap">
              <Breadcrumbs location={location} />
            </div>
          </section>
          <div className="project-content">
            <div className="project-content-wrap">
              <h1 className="project-name">{projectName}</h1>
              <span className="project-location">{projectLocation}</span>
              <div className="project-information">
                <div className="col-left">
                  {projectType && (
                    <>
                      <span className="heading">Type</span>
                      <p>{projectType}</p>
                    </>
                  )}
                  {costPerSquareMetre && (
                    <>
                      <span className="heading">Cost Per SQM</span>
                      <p>{costPerSquareMetre}</p>
                    </>
                  )}
                  {floorArea && (
                    <>
                      <span className="heading">Floor Area</span>
                      <p>{floorArea}</p>
                    </>
                  )}
                  {duration && (
                    <>
                      <span className="heading">Duration</span>
                      <p>{duration}</p>
                    </>
                  )}
                </div>
                <div className="col-right">
                  {jobDescription && (
                    <>
                      <span className="heading">Job Description</span>
                      <div className="job-description" dangerouslySetInnerHTML={{__html: jobDescription}} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <RelatedProjects
            relatedProjects={relatedProjects}
            projectGallerySlug={projectGallerySlug}
          />
          <div className="back-link">
            <Link to={`/${projectGallerySlug}/`}>Back to the gallery</Link>
          </div>
          <TestimonialsBlock
            hasLink
            link='/contact-us/'
            linkLabel='Book your free consultation now'
            siteMetadata={siteMetadata}
          />
          <LogosBlock
            heading='Some of our valued clients'
            usingGlobalLogos
            siteMetadata={siteMetadata}
          />
          <ContactInformationBlock
            siteMetadata={siteMetadata}
          />
        </article>
      </main>
    );
}

ProjectTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const Project = (props) => {
  const {
    data,
    // pageContext,
    location,
  } = props;
  const {
    wordpressWpProject: project,
    relatedProjects,
    site,
  } = data;
  if (!project) return null;
  const {
    title,
    yoast,
    featuredImage,
  } = project;
  const { siteMetadata } = site;
  const {
    title: siteTitle,
  } = siteMetadata;
  return (
    <Layout>
      <SEO
        title={`${yoast.metaTitle || title} | ${siteTitle}`}
        desc={yoast.metaDescription}
        banner={featuredImage && featuredImage.source_url}
      />
      <ProjectTemplate
        {...project}
        location={location}
        relatedProjects={relatedProjects}
        siteMetadata={siteMetadata}
      />
    </Layout>
  )
}

Project.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Previewable(Project)

export const pageQuery = graphql`
  query ProjectByID($id: String!, $primaryCategoryId: Int) {
    site {
      siteMetadata {
        title
        wordpressUrl
        siteUrl
        projectGallerySlug
        projectCategorySlug
        functionsUrl
      }
    }
    wordpressWpProject(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
      featuredImage: featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      categories {
        name
        slug
      }
      acf {
        projectName
        projectLocation
        projectType
        costPerSquareMetre
        floorArea
        duration
        shortDescription
        jobDescription
        images {
          dummy
          image {
            id
            alt_text
            localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 70) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
  relatedProjects: allWordpressWpProject(
    filter: {categories: {elemMatch: {wordpress_id: {eq: $primaryCategoryId}}}, id: {ne: $id}},
    sort: {fields: date, order: DESC},
    limit: 3
  ) {
    edges {
      node {
        slug
        title
        featuredImage: featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 70) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
}
`
